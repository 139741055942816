<template>
    <div class="home">
        <el-carousel indicator-position="outside" height="460" :interval="tiem">
          <el-carousel-item v-for="(item,index) in itemData.index_carousels" :key="index" >
            <div class="item" :style="{backgroundImage: 'url(' + item.address + ')'}"></div>
          </el-carousel-item>
        </el-carousel>
        <div class="indexMain">
          <div class="wd">
            <div class="one newsBox">
              <div class="newsBoxLeft">
                <div class="indexTitle">
                    <router-link :to="{ path:'/Shellone'}" replace>MORE...</router-link>
                  <b @click="getIndexDate">会员风采</b>
                </div>
                <div class="slideBox">
                  <!-- 轮播图 -->
                   <el-carousel height="228px">
                    <el-carousel-item v-for="(item,index) in itemData.vice_carousels" :key="index">
                        <!-- <div class="miniLb"></div> -->
                         <div class="miniLb" :style="{backgroundImage: 'url(' + item.address + ')'}"></div>

                    </el-carousel-item>
                  </el-carousel>
                </div>
                <ul class="indexNews">
                  <li v-for="(item,index) in itemData.member_deeds_articles" :key="index">
                  <b>{{setDate(item.created_at)}}</b>
                  <router-link :to="{ path: 'Shellone' ,query:{id:4,c_id:item.id} }" replace>{{item.title}}</router-link>
                  </li>
                </ul>
              </div>
              <div class="newsBoxRight">
                <div class="indexTitle"><router-link :to="{ path: '/Shellone'}" replace>MORE...</router-link><b>会员荣誉</b></div>
                <div class="slideNotice">
                    <ul class="indexNews">
                          <li v-for="(item,index) in itemData.member_honor_articles" :key="index">
                          <b>{{setDate(item.created_at)}}</b>
                          <!-- <a href="javascript:;">{{item.title}}</a> -->
                            <router-link :to="{ path: 'Shellone' , query:{id:4,c_id:item.id} }" replace>{{item.title}}</router-link>
                          </li>
                    </ul>
                  </div>
              </div>

            </div>
          </div>
        </div>


        <div class="owl-stage-outer ">
          <div class="owl-stage wd" >
            <div class="owl-item active" style="width: 390px; margin-right: 5px;">
              <div class="">
                <router-link :to="{ path: itemData.ad_pics_top[0].link_url  }" replace v-if="itemData.ad_pics_top[0]">
                <img :src="itemData.ad_pics_top[0].address" alt="" title="">
                </router-link>
                 <a href="javascript:;" v-else><img src="../../assets/img/skin/20170112110410_941.jpg" alt="" title="" ></a>

              </div>
            </div>
            <div class="owl-item active" style="width: 390px; margin-right: 5px;">
              <div class="">
               <router-link :to="{ path: itemData.ad_pics_top[1].link_url  }" replace v-if="itemData.ad_pics_top[1]">
                <img :src="itemData.ad_pics_top[1].address" alt="" title="">
                </router-link>
                 <a href="javascript:;" v-else><img src="../../assets/img/skin/20170112110410_941.jpg" alt="" title="" ></a>
              </div>
            </div>
            <div class="owl-item active" style="width: 390px; margin-right: 5px;">
              <div class="">
                <router-link :to="{ path: itemData.ad_pics_top[2].link_url  }" replace v-if="itemData.ad_pics_top[2]">
                <img :src="itemData.ad_pics_top[2].address" alt="" title="">
                </router-link>
                 <a href="javascript:;" v-else><img src="../../assets/img/skin/20170112110410_941.jpg" alt="" title="" ></a>
              </div>
            </div>
          </div>
          <div class="owl-stage wd">
          <div class="owl-stage wd" >
            <div class="owl-item active" style="width: 292px; margin-right: 5px; margin-top: 10px;" >
              <div class="">
                <router-link :to="{ path: itemData.ad_pics_top[3].link_url  }" replace v-if="itemData.ad_pics_top[3]">
                 <img :src="itemData.ad_pics_top[3].address" alt="" title="">
                </router-link>
                 <a href="javascript:;" v-else><img src="../../assets/img/skin/20170112110410_941.jpg" alt="" title="" ></a>
              </div>
            </div>
            <div class="owl-item active" style="width: 292px; margin-right: 5px; margin-top: 10px;" >
              <div class="">
                <router-link :to="{ path: itemData.ad_pics_top[4].link_url  }" replace v-if="itemData.ad_pics_top[4]">
                 <img :src="itemData.ad_pics_top[4].address" alt="" title="" >
                </router-link>
                 <a href="javascript:;" v-else><img src="../../assets/img/skin/20170112110410_941.jpg" alt="" title="" ></a>
              </div>
            </div>
            <div class="owl-item active" style="width: 292px; margin-right: 5px; margin-top: 10px;" >
              <div class="">
                 <router-link :to="{ path: itemData.ad_pics_top[5].link_url  }" replace v-if="itemData.ad_pics_top[5]">
                 <img :src="itemData.ad_pics_top[5].address" alt="" title="" >
                </router-link>
                 <a href="javascript:;" v-else><img src="../../assets/img/skin/20170112110410_941.jpg" alt="" title="" ></a>
              </div>
            </div>
            <div class="owl-item active" style="width: 292px; margin-right: 5px; margin-top: 10px;" >
              <div class="">
                 <router-link :to="{ path: itemData.ad_pics_top[6].link_url  }" replace v-if="itemData.ad_pics_top[6]">
                 <img :src="itemData.ad_pics_top[6].address" alt="" title="" >
                </router-link>
                 <a href="javascript:;" v-else><img src="../../assets/img/skin/20170112110410_941.jpg" alt="" title="" ></a>
              </div>
            </div>
          </div>
          </div>
        </div>

        <div class="indexMain">
          <div class="wd">
            <div class="one newsBox">
              <div class="newsBoxLeft">
                <div class="indexTitle">
                  <router-link :to="{ path: '/Shellone'}" replace>MORE...</router-link>
                  <b>光荣榜</b>
                </div>
                <div class="slideBox">
                  <!-- 轮播图 -->
                  <el-carousel height="228px">
                    <el-carousel-item v-for="(item,index) in itemData.secondary_articles" :key="index">
                        <!-- {{item}} -->
                        <!-- `url(${imgUrl})` -->
                        <!-- <div class="assets" :style="{ 'background': 'url(' + require(src) + ') no-repeat center center', 'background-size': '100% 100%'}"></div> -->
                        <div class="miniLb" :style="{backgroundImage: 'url(' + item.address + ')'}"></div>
                    </el-carousel-item>
                  </el-carousel>
                </div>
               <ul class="indexNews">
                  <li v-for="(item,index) in itemData.member_scroll_articles" :key="index">
                  <b>{{setDate(item.created_at)}}</b>
                  <!-- <a href="#">{{item.title}}</a> -->
                    <router-link :to="{ path: 'Shellone' , query:{id:4,c_id:item.id} }" replace>{{item.title}}</router-link>
                  </li>
               </ul>
              </div>
               <div class="newsBoxRight">
                <div class="indexTitle"  style=" padding-bottom: 0px;"><router-link :to="{ path: '/Shellone'}" replace></router-link><b>活动视频</b></div>
                <div class="slideNotice" style="">
                  <!-- autoplay -->
                      <video class="video-js" controls   width="360" height="280"  
                      :src="itemData.activity_video[0].address" data-setup="{}">
                      <!-- <source src="rtmp://live.ibugoo.cn/9534/9534?auth_key=1622390400-0-0-21582b3a681754cfc958230ed14a9b76" type="rtmp/flv">  -->
                      <!-- 如果上面的rtmp流无法播放，就播放hls流 -->  
                      <source :src="itemData.activity_video[0].address" type='application/x-mpegURL'>  
                      <p class="vjs-no-js">播放视频需要启用 JavaScript，推荐使用支持HTML5的浏览器访问。  
                      To view this video please enable JavaScript, and consider upgrading to a web browser that  
                    
                      </p>  
                  </video>
                  </div>
              </div>

            </div>
          </div>
        </div>

        <div class="owl-stage-outer ">
        <div class="owl-stage wd" >
            <div class="owl-item active" style="width: 394px; margin-right: 5px;">
              <div class="">
                 <router-link :to="{ path: itemData.ad_pics_mid[0].link_url  }" replace v-if="itemData.ad_pics_mid[0]">
                 <img :src="itemData.ad_pics_mid[0].address" alt="" title="" >
                </router-link>
                 <a href="javascript:;" v-else><img src="../../assets/img/skin/20160614102041_495.jpg" alt="" title="" ></a>
               
              </div>
            </div>
            <div class="owl-item active" style="width: 394x; margin-right: 5px;">
              <div class="">
                <router-link :to="{ path: itemData.ad_pics_mid[1].link_url  }" replace v-if="itemData.ad_pics_mid[1]">
                 <img :src="itemData.ad_pics_mid[1].address" alt="" title="" >
                </router-link>
                 <a href="javascript:;" v-else><img src="../../assets/img/skin/20160614102041_495.jpg" alt="" title="" ></a>
              </div>
            </div>
            <div class="owl-item active" style="width: 394px; margin-right: 5px;">
              <div class="">
                <router-link :to="{ path: itemData.ad_pics_mid[2].link_url  }" replace v-if="itemData.ad_pics_mid[2]">
                 <img :src="itemData.ad_pics_mid[2].address" alt="" title="" >
                </router-link>
                 <a href="javascript:;" v-else><img src="../../assets/img/skin/20160614102041_495.jpg" alt="" title="" ></a>
              </div>
            </div>
          </div>
        </div>

        <div class="indexMain">
          <div class="wd">
            <div class="one newsBox">
              <div class="newsBoxLeft" style="width:380px">
                <div class="indexTitle">
                  <router-link :to="{ path: '/Shellone'}" replace>MORE...</router-link>
                  <b>会员产品</b>
                </div>
               <ul class="indexNews">
                  <li v-for="(item,index) in itemData.member_scroll_articles" :key="index">
                  <b>{{setDate(item.created_at)}}</b>
                    <router-link :to="{ path: 'Shellone' , query:{id:4,c_id:item.id} }" replace>{{item.title}}</router-link>
                  </li>
               </ul>
              </div>
               <div class="newsBoxRight">
                <div class="indexTitle" style=" padding-bottom: 0px;"><router-link  replace></router-link><b>公益视频</b></div>
                <div class="slideNotice">
                  <!-- autoplay -->
                    <video class="video-js" controls   width="360" height="280"  
                      :src="itemData.public_video[0].address" data-setup="{}">
                      <!-- <source src="rtmp://live.ibugoo.cn/9534/9534?auth_key=1622390400-0-0-21582b3a681754cfc958230ed14a9b76" type="rtmp/flv">  -->
                      <!-- 如果上面的rtmp流无法播放，就播放hls流 -->  
                      <source :src="itemData.public_video[0].address" type='application/x-mpegURL'>  
                
                  </video>
                  </div>
              </div>

            </div>
          </div>
        </div>

        <div class="owl-stage-outer ">
        <div class="owl-stage wd" >
            <div class="owl-item active" style="width: 592px; margin-right: 10px;">
              <div class="">
                <!-- <a href="javascript:;"><img src="../../assets/img/skin/adimg7.jpg" alt="" title=""></a> -->
                <router-link :to="{ path: itemData.ad_pics_tail[0].link_url  }" replace v-if="itemData.ad_pics_tail[0]">
                 <img :src="itemData.ad_pics_tail[0].address" alt="" title="" >
                </router-link>
                 <a href="javascript:;" v-else><img src="../../assets/img/skin/adimg7.jpg" alt="" title="" ></a>
              </div>
            </div>
            <div class="owl-item active" style="width: 592px; margin-right: 5px;">
              <div class="">
                 <router-link :to="{ path: itemData.ad_pics_tail[1].link_url  }" replace v-if="itemData.ad_pics_tail[1]">
                 <img :src="itemData.ad_pics_tail[1].address" alt="" title="" >
                </router-link>
                 <a href="javascript:;" v-else><img src="../../assets/img/skin/adimg7.jpg" alt="" title="" ></a>
              </div>
            </div>
          </div>
        </div>
        <div class="link wd">
          <div class="linkText">友情链接 :</div>
          <div class="fLink"><a href="https://www.bjah.cn/" target="_blank">北京市安徽企业商会</a> <a href="https://www.huishangorg.cn/" target="_blank">安徽国际徽商交流协</a> <a href="https://www.gzahsh.com/" target="_blank">贵州安徽商会</a> <a href="https://www.jchs.cn/index.html" target="_blank">湖北省安徽商会</a> <a href="https://www.nbahsh.org/" target="_blank">宁波安徽商会</a> <a href="https://www.zsahsh.com/index.asp" target="_blank">中山市安徽商会</a> <a href="https://www.hzhssh.com/" target="_blank">惠州市安徽商会</a> <a href="https://www.cqaccc.com/" target="_blank">重庆市安徽商会</a> <a href="https://www.fjxhs.com/" target="_blank">福建省安徽商会</a> <a href="https://www.sdahsh.com/" target="_blank">山东省安徽商会</a> <a href="https://www.ynahsh.com/" target="_blank">云南安徽商会</a> <a href="https://www.cnahcc.com/" target="_blank">河南省安徽商会</a> <a href="https://www.gdanhuish.com/" target="_blank">广东省安徽商会</a> </div>
        </div>

    </div>
</template>

<script>

    export default {
      components:{

      },
        data() {
            return {
              allimg:['1','2'],
              itemData:'',
              tiem:20000,
              slideBoxImg2:[require('../../assets/img/allimg/c170523/1495523464c020-C344_lit.jpg'),
             require('../../assets/img/allimg/c170523/149552346440N0-39459_lit.jpg'),
             require('../../assets/img/allimg/c170523/149552346402320-2U13_lit.jpg'),
             require('../../assets/img/allimg/c170523/149552346363V0-1YB_lit.jpg')],
            }
        },
        created(){
          this.getIndexDate()
        },
        methods:{
          getIndexDate(){
            this.$http.get('/api/ahsh/index').then(res=>{
              let {data,code} = res.data
              if (code == 0) {
                this.itemData = data
                console.log(this.itemData)
              }
            })
          },
          setDate(date){
            let time = new Date(date)
            let item = time.getMonth()+1+'-'+time.getDate()
            return item
          }
        },
        mounted(){

        }
    }
</script>

<style scoped lang="less">
.home{
  /deep/ .el-carousel__container{
    height: 460px;
    z-index: 22;
  }
  .item{
    width: 100%;
    height: 460px;
    background-image: url('../../assets/img/bg/bgmain.jpg');
    position: absolute;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    }
  .indexMain{
    background-color: #fff;
    .newsBox{
      height: 365px;
      overflow: hidden;
      .newsBoxLeft {
          float: left;
          width: 790px;
          .indexTitle {
            height: auto;
            overflow: hidden;
            padding: 42px 0 24px 30px;
            background: url(../../assets/img/skin/icon2.png) no-repeat 0 40px;
            a {
              display: block;
              color: #004987;
              font-size: 1.4em;
              font-weight: bold;
              margin: 11px 10px 0 0;
              float: right;
            }
            span{
              display: inline-block;
              color: #004987;
              font-size: 22px;
            }
        }
        .slideBox {
            width: 385px;
            height: 230px;
            overflow: hidden;
            position: relative;
            float: left;
            // background: #000;
            .miniLb{
              width: 100%;
              height: 100%;
              background-image:url('../../assets/img/allimg/170523/1453255628-0-lp.jpg');
              position: absolute;
              height: 100%;
              background-position: center center;
              background-repeat: no-repeat;
              background-size: auto;
            }
        }

      }
      .newsBoxRight {
          float: right;
          position: relative;
          width: 361px;
          .indexTitle {
              height: auto;
              overflow: hidden;
              padding: 42px 0 24px 30px;
              background: url(../../assets/img/skin/icon2.png) no-repeat 0 40px;
              a {
              display: block;
              color: #004987;
              font-size: 1.4em;
              font-weight: bold;
              margin: 11px 10px 0 0;
              float: right;
            }
            span{
              display: inline-block;
              color: #004987;
              font-size: 22px;
            }
          }
          .slideNotice {
            position: relative;
            .indexNews {
                  width: 361px;
              }
            .indexNews {
                float: right;
                height: auto;
                overflow: hidden;
                // width: 373px;
                padding-top: 2px;
            }
          }
          .iconList{
              height: auto;
              overflow: hidden;
              margin-top: 60px;
          }
      }
    }
  }
  .indexNews {
    float: right;
    height: auto;
    overflow: hidden;
    width: 373px;
    padding-top: 2px;
    li {
        height: 38px;
        overflow: hidden;
        background: url(../../assets/img/skin/dian.jpg) repeat-x 0 bottom;
        a {
            display: block;
            color: #000;
            height: 37px;
            line-height: 37px;
            overflow: hidden;
            font-size: 14px;
        }
        b {
          display: block;
          color: #004987;
          height: 37px;
          line-height: 37px;
          overflow: hidden;
          font-size: 14px;
          padding-left: 3px;
          width: 52px;
          float: left;
      }
    }
  }
  .owl-carousel.owl-loaded {
      display: block;
  }
  .owl-stage-outer {
  position: relative;
  overflow: hidden;
  text-align: center;
}
  .owl-carousel {
    display: none;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    z-index: 1;


    .owl-item img {
        display: block;
        width: 100%;
    }
  }
  .owl-item {
      position: relative;
      min-height: 1px;
      float: left;
      user-select: none;
      .item{
        width: 100%;
        overflow: hidden;
      }
    }
}
  
</style>